// ===============================|| Blank Layout ||=============================== //
<script setup lang="ts">
import { useCustomizerStore } from '@/stores/customizer';
const router = useRouter()
const customizer = useCustomizerStore();

</script>
<template>
  <NuxtPwaManifest />
  <v-app :theme="customizer.actTheme">
    <v-main>
      <NuxtPage v-if="router.currentRoute.value.name === 'index'" />
    </v-main>
  </v-app>
</template>


